import React from "react"
import { Link, graphql,StaticQuery,navigate } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"
import AnimatedLogo from "../components/animated-logo"
import Helmet from "react-helmet";
import Img from "gatsby-image";
import {  HelpCircle } from "react-feather";

const IndexPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout>
    <Helmet
             title={'MSDNC'}
             titleTemplate={`%s`}
             meta={[
               {
                 property: "og:title",
                 content: 'MSDNC'
               },
               {
                 property: "twitter:title",
                 content: 'MSDNC'
               },
               {
                 property: "twitter:description",
                 content: "The Place for Surface Level Analysis, Biased Political Commentary & Ill-Informed Perspectives"
               },
 
               {
                 name: "description",
                 content: "The Place for Surface Level Analysis, Biased Political Commentary & Ill-Informed Perspectives"
               },
               {
                 property: "og:image",
                 content:
                 "https://msdncnews.org" +
                 data.fileName.childImageSharp.fixed.src
               },
               {
                 property: "twitter:image",
                 content:
                 "https://msdncnews.org" +
                 data.fileName.childImageSharp.fixed.src
               },
 
               {
                 property: "og:image:url",
                 content:
                 "https://msdncnews.org" +
                 data.fileName.childImageSharp.fixed.src
               },
               {
                 property: "og:type",
                 content: "website"
               },
               {
                 name: "twitter:card",
                 content: "summary_large_image"
               },
               {
                 name: "twitter:creator",
                 content: "dkrasniy"
               }
             ]}
           />
 


 <div>
    
    <div className="flex flex-wrap mx-auto">
   
      {posts.slice(0, 1).map(({ node: post }, key) => (
        <>
          <Link className="flex flex-wrap w-full relative" to={post.fields.slug} style={{'backgroundSize':'cover'}}>
            <div className="img w-full absolute top-0 w-full z-0 left-0 h-full cover-style-img">
            <Img className="h-full" alt={`featured image thumbnail for post ${post.title}`} fluid={post.frontmatter.featuredimage.childImageSharp.fluid}/>
            </div>
            <div className="max-w-3xl w-full mx-auto  flex justify-center flex-col p-8 px-4 md:px-0 z-50">
            <div className="pt-32 max-w-md"><div><span className="px-3 py-1 rounded uppercase tracking-wider camber text-xs text-white bg-red-800 inline">Latest</span></div>
              <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-white text-shadow py-2 camber md:leading-tight">
                {post.frontmatter.title}
              </h2>
              
              <span className="block text-white text-shadow text-sm">
                {post.excerpt.substring(0, 96) + "..."}
              </span>

              {/*                   
                <span className="inline-block mt-2 bg-white">Read More</span>
              */}
            </div>
            </div>
          </Link>
        </>
      ))}
    </div>
  </div>

  <span className="lora max-w-3xl mx-auto px-4 md:px-0 text-gray-900 text-xl md:text-2xl py-6 pb-2 md:pb-4 font-semibold block">
    Latest Stories
  </span>
  <div className="flex flex-wrap max-w-3xl mx-auto pb-12 px-4 md:px-0">
    {posts &&
      posts.slice(1).map(({ node: post }, key) => (
        <>
          <div className={`block w-full md:w-1/2 lg:w-1/3`} key={post.id}>
            <div className="my-1 md:px-2 border-b md:border-none">
              <div className="bg-white">
                <Link className="flex flex-wrap" to={post.fields.slug}>
                  <div className="img w-1/3 md:w-full flex items-center">
                    {post.frontmatter.featuredimage ? (
                      <Img
                        className="w-full border-2  border-2 md:h-40"
                        alt={`featured image thumbnail for post ${post.title}`}
                        fluid={
                          post.frontmatter.featuredimage.childImageSharp
                            .fluid
                        }
                      />
                    ) : null}
                  </div>
                  <div className="w-2/3 md:w-full py-6 px-4 md:px-0 flex flex-col justify-center md:flex-none">
                    <span className="red-line-pre relative text-gray-800 block font-semibold camber leading-tight mb-1">
                      {post.frontmatter.title}
                    </span>
                    <span className="text-xs text-gray-500 block">
                      {post.frontmatter.date}
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </>
      ))}
  </div>


   </Layout>
  )
}


export default () => (
  <StaticQuery
    query={graphql`
      query HomeBlog {
        fileName: file(relativePath: { eq: "images/default.png" }) {
          childImageSharp {
            fixed(width: 800, height: 420, quality: 80) {
              width
              height
              src
              srcSet
              srcWebp
            }
          }
        }
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <IndexPage data={data} count={count} />}
  />
);


